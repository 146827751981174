// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "menu-items";
import { useSelector } from "react-redux";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const isAdmin = useSelector((state) => state.auth.user?.admin);
  const navItems = menuItem.items.map((item) => {
    if (isAdmin || item.id !== "admin-menu") {
      switch (item.type) {
        case "group":
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    } else {
      return null;
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
