import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// // utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));
// book
const MyBookList = Loadable(lazy(() => import("views/book/MyBookList")));
const BookUpload = Loadable(lazy(() => import("views/book/BookUpload")));
const BookSellingList = Loadable(
  lazy(() => import("views/book/BookSellingList"))
);
const BookPay = Loadable(lazy(() => import("views/book/Pay")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
    },
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <DashboardDefault />,
        },
      ],
    },
    // {
    //   path: 'sample-page',
    //   element: <SamplePage />
    // },
    {
      path: "book/upload",
      element: <BookUpload />,
    },
    {
      path: "book/my-list",
      element: <MyBookList />,
    },
    {
      path: "book/selling-list",
      element: <BookSellingList />,
    },
    {
      path: "book/pay/:id",
      element: <BookPay />,
    },
  ],
};

export default MainRoutes;
