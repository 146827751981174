import dashboard from "./dashboard";
// import pages from './pages';
// import utilities from './utilities';
import other from "./other";
import admin from "./admin";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, admin, other],
};

export default menuItems;
