import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

// book
const Users = Loadable(lazy(() => import("views/admin/Users")));
const BookUpload = Loadable(lazy(() => import("views/admin/book/Upload")));
const BookUserList = Loadable(lazy(() => import("views/admin/book/UserList")));
const BookSellingList = Loadable(
  lazy(() => import("views/admin/book/SellingList"))
);
const PaymentHistory = Loadable(
  lazy(() => import("views/admin/PaymentHistory"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout admin={true} />,
  children: [
    {
      path: "admin/user",
      element: <Users />,
    },
    {
      path: "admin/book/upload",
      element: <BookUpload />,
    },
    {
      path: "admin/book/selling-list",
      element: <BookSellingList />,
    },
    {
      path: "admin/book/user-list",
      element: <BookUserList />,
    },
    {
      path: "admin/payment-history",
      element: <PaymentHistory />,
    },
  ],
};

export default MainRoutes;
