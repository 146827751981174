import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import FullScreenRoutes from "./FullScreenRoutes";
import AdminRoutes from "./AdminRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    AdminRoutes,
    AuthenticationRoutes,
    FullScreenRoutes,
  ]);
}
