// assets
import {
  IconBrandChrome,
  IconHelp,
  IconBook,
  IconBookUpload,
  IconBook2,
  IconEditCircle,
  IconShoppingCart,
} from "@tabler/icons-react";
// constant
const icons = {
  IconBrandChrome,
  IconHelp,
  IconBook,
  IconBookUpload,
  IconBook2,
  IconEditCircle,
  IconShoppingCart,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: "user-menu",
  type: "group",
  children: [
    {
      id: "whiteboard",
      title: "Whiteboard",
      type: "item",
      url: "whiteboard",
      icon: icons.IconEditCircle,
      target: "_blank",
    },
    {
      id: "book",
      title: "Book",
      type: "collapse",
      icon: icons.IconBook2,

      children: [
        {
          id: "book/upload",
          title: "Upload",
          type: "item",
          url: "/book/upload",
          icon: icons.IconBookUpload,
        },
        {
          id: "book/my-list",
          title: "My List",
          type: "item",
          url: "/book/my-list",
          icon: icons.IconBook,
        },
        {
          id: "book/selling-list",
          title: "Buy",
          type: "item",
          url: "/book/selling-list",
          icon: icons.IconShoppingCart,
        },
      ],
    },
    // {
    //   id: "sample-page",
    //   title: "Sample Page",
    //   type: "item",
    //   url: "/sample-page/page",
    //   icon: icons.IconBrandChrome,
    //   breadcrumbs: false,
    // },
  ],
};

export default other;
