import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";

// login option 3 routing
const BookView = Loadable(lazy(() => import("views/book/BookView")));

const Whiteboard = Loadable(lazy(() => import("views/whiteboard")));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const FullScreenRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/book/view/:id",
      element: <BookView />,
    },
    {
      path: "whiteboard",
      element: <Whiteboard />,
    },
    {
      path: "whiteboard/from-url/:url",
      element: <Whiteboard />,
    },
    {
      path: "whiteboard/:bookId/:pageId",
      element: <Whiteboard />,
    },
  ],
};

export default FullScreenRoutes;
