// assets
import {
  IconBrandChrome,
  IconHelp,
  IconBook,
  IconBookUpload,
  IconBook2,
  IconEditCircle,
  IconUsers,
  IconCoins,
} from "@tabler/icons-react";

// constant
const icons = {
  IconBrandChrome,
  IconHelp,
  IconBook,
  IconBookUpload,
  IconBook2,
  IconEditCircle,
  IconUsers,
  IconCoins,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: "admin-menu",
  type: "group",
  title: "Admin",
  children: [
    {
      id: "user",
      title: "Users",
      type: "item",
      url: "/admin/user",
      icon: icons.IconUsers,
    },
    // {
    //   id: "book",
    //   title: "Books",
    //   type: "item",
    //   url: "/admin/book",
    //   icon: icons.IconBook2,
    // },
    {
      id: "book",
      title: "Book",
      type: "collapse",
      icon: icons.IconBook2,

      children: [
        {
          id: "book/upload",
          title: "Upload",
          type: "item",
          url: "/admin/book/upload",
          icon: icons.IconBookUpload,
        },
        {
          id: "book/selling-list",
          title: "Selling Book List",
          type: "item",
          url: "/admin/book/selling-list",
          icon: icons.IconBook,
        },
        {
          id: "book/user-list",
          title: "User Book List",
          type: "item",
          url: "/admin/book/user-list",
          icon: icons.IconBook,
        },
      ],
    },
    {
      id: "payment-history",
      title: "Payment History",
      type: "item",
      url: "/admin/payment-history",
      icon: icons.IconCoins,
    },
    // {
    //   id: "sample-page",
    //   title: "Sample Page",
    //   type: "item",
    //   url: "/sample-page/page",
    //   icon: icons.IconBrandChrome,
    //   breadcrumbs: false,
    // },
  ],
};

export default other;
