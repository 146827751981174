import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        hello: "Hi, Welcome Back",
        enterCredential: "Enter your credentials to continue",
        signInWithEmailAddress: "Sign in with Email address",
        emailAddress: "Email Address",
        "Must be a valid email": "Must be a valid email",
        "Email is required": "Email is required",
        Password: "Password",
        "Password is required": "Password is required",
        "Remember me": "Remember me",
        "Forgot Password?": "Forgot Password?",
        "Sign in": "Sign in",
        "Don't have an account?": "Don't have an account?",
        "Sign up": "Sign up",
        "Sign up with Email address": "Sign up with Email address",
        "First Name": "First Name",
        "Last Name": "Last Name",
        "First name is required": "First name is required",
        "Last name is required": "Last name is required",
        "Already have an account?": "Already have an account?",
        Dashboard: "Dashboard",
        Whiteboard: "Whiteboard",
        Book: "Book",
        Upload: "Upload",
        "My List": "My List",
        "My Book List": "My Book List",
        "Reorder Page": "Reorder Page",
        Save: "Save",
        "Input book title": "Input book title",
        Confirm: "Confirm",
        Language: "Language",
        "Font Family": "Font Family",
        "Border Radius": "Border Radius",
        Admin: "Admin",
        "User Manage": "User Manage",
        // Other English translations
      },
    },
    tr: {
      translation: {
        hello: "Merhaba,Hoş Geldiniz",
        enterCredential: "Devam etmek için kimlik bilgilerinizi girin",
        signInWithEmailAddress: "E-posta adresiyle oturum açın",
        emailAddress: "E-posta Adresi",
        "Must be a valid email": "Geçerli bir e-posta olmalı",
        "Email is required": "E-Posta Adresiniz gereklidir",
        Password: "Şifre",
        "Password is required": "Şifre gereklidir",
        "Remember me": "Beni Hatırla",
        "Forgot Password?": "Parolanızı mı unuttunuz?",
        "Sign in": "Kayıt olmak",
        "Don't have an account?": "Hesabınız yok mu?",
        "Sign up": "Üye olmak",
        "Sign up with Email address": "E-posta adresiyle kaydolun",
        "First Name": "Adınız",
        "Last Name": "Soyadınız",
        "First name is required": "İsim gerekli",
        "Last name is required": "Soyisim gerekli",
        "Already have an account?": "Hesabınız var mı?",
        Dashboard: "Gösterge Paneli",
        Whiteboard: "Beyaz tahta",
        Book: "Kitap",
        Upload: "Kitap Oluştur",
        "My List": "Listem",
        "My Book List": "Kitap Listem",
        "Reorder Page": "Kitabınızı Oluşturun",
        Save: "Kaydetmek",
        "Input book title": "Kitap Adınızı Girin",
        Confirm: "Kaydet",
        Language: "Dil",
        "Font Family": "Font ailesi",
        "Border Radius": "Sınır Yarıçapı",
        Admin: "Yönetici",
        "User Manage": "Kullanıcı Yönetimi",
        "Add New User": "Yeni Kullanıcı Ekle",
        Email: "E-posta",
        Active: "Aktif",
        Action: "Aksiyon",
        "Are you sure to save?": "Kaydettiğinizden emin misiniz?",
        "Are you sure to delete?": "Silmek istediğinize emin misiniz?",
        "Is this account active?": "Bu hesap aktif mi?",
        "Is this account admin?": "Bu hesap yönetici mi?",
        Cancel: "İptal etmek",
        "Input book price": "Giriş kitabı fiyatı",
        "Selling Book List": "Kitap Listesi Satışı",
        "User Book List": "Kullanıcı Kitabı Listesi",
        "Selling Book Manage": "Kitap Yönetimi Satışı",
        Thumbnail: "Küçük resim",
        Title: "Başlık",
        Price: "Fiyat",
        "User Book Manage": "Kullanıcı Kitabı Yönetimi",
        "Payment History": "ödeme geçmişi",
        "Book Title": "Kitap başlığı",
        Status: "Durum",
        Pending: "Askıda olması",
        Success: "Başarı",
        Fail: "Hata",
        Reason: "Sebep",
        Buy: "Satın almak",
        "Buy Books": "Kitaplar satın almak",
        "Order detail": "Sipariş detayı",
        "Page count": "Sayfa sayısı",
        "Price (TRY)": "Fiyat (TL)",
        "Billing address": "Fatura Adresi",
        Back: "Geri",
        "Already Bought": "Zaten satın alınmış",
        // Other Turkish translations
      },
    },
    // Add more languages and translations as needed
  },
  lng: "tr", // Default language
  fallbackLng: "tr", // Fallback language
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;
